<!-- 搜索页面 -->
<template>
    <div style="height: 100%;padding: 20px;background: #fff;display: flex;flex-direction:column;">
        <div>
            <div style="font-size:16px;font-weight: bold;margin-bottom: 10px;">搜索</div>
             <el-tooltip class="item" effect="dark" content="搜索与筛选不可同时进行" placement="bottom-end" :disabled="!tooltip">
                <el-input placeholder="请输入手机号码/用户名称/门店名称" prefix-icon="el-icon-search" v-model="keyword" :disabled="tooltip" @focus='onFocus'/>
            </el-tooltip>
        </div>
        <div>
            <div style="font-size:16px;font-weight: bold;margin-bottom: 10px;margin-top: 40px;">等级</div>
            <div style="display:flex;flex-wrap:wrap;">
                <div :class="item.checked == true ? 'select' : 'noSelect'" v-for="(item, index) in levelList"
                    :key="item.id" @click="selectLevel(index, item)">{{ item.name }}</div>
            </div>
        </div>
        <div>
            <div style="font-size:16px;font-weight: bold;margin-bottom: 10px;margin-top: 30px;">时间范围</div>
            <div style="display: flex;">
                <div style="display:flex;justify-content: center;align-items: center;width: 100%;">
                    <div :class="currentDate ? 'time' : 'Notime'" @click="show = true">{{ currentDate ? currentDate : '开始时间' }}
                    </div>
                    <img src="../../images/arrow.png" alt="" style="width:30px;height: 30px;margin: 0 50px;">
                    <div :class="currentDate2 ? 'time' : 'Notime'" @click="show2 = true">{{ currentDate2 ? currentDate2 : '结束时间' }}
                    </div>
                </div>
                <van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
                    <van-datetime-picker v-model="currentDate" type="date" title="选择年月日" :min-date="minDate"
                        :max-date="maxDate" @confirm='confirm' @cancel='cancel' />
                </van-popup>
                <van-popup v-model="show2" position="bottom" :style="{ height: '30%' }">
                    <van-datetime-picker v-model="currentDate2" type="date" title="选择年月日" :min-date="minDate"
                        :max-date="maxDate" @confirm='confirm2' @cancel='cancel2' />
                </van-popup>
            </div>
        </div>
        <div>
            <div style="font-size:16px;font-weight: bold;margin-bottom: 10px;margin-top: 30px;">时长/小时</div>
            <el-slider v-model="value" range :marks="marks" :min="0" :max="100">
            </el-slider>
        </div>
        <div style="margin-top:50px;display: flex;justify-content: center;">
            <el-button @click="reset">重置</el-button>
            <el-button type="primary" @click="list" v-if="!keyword">筛选</el-button>
            <el-button type="primary" v-if="keyword" @click="list2">搜索</el-button>
        </div>
    </div>
</template>

<script>
import { levelList, agentTrainees } from '../../apis/index'
export default {
    data() {
        return {
            tableData2:[],
            ids:[],
            tooltip:false,
            levelId: '',
            levelList: [],
            keyword: '',
            minDate: new Date(2015, 0, 1),
            maxDate: new Date(2025, 10, 1),
            currentDate: '',
            currentDate2: '',
            show: false,
            show2: false,
            value: [0, 0],
            marks: {
                0: '0',
                10: '10',
                20: '20',
                30: '30',
                40: '40',
                50: '50',
                60: '60',
                70: '70',
                80: '80',
                90: '90',
                100: '100'
            }
        }
    },
    created() {
        this.leave()
        // this.list2()
        // let d=new Date()
        // this.currentDate2=this.currentDate=new Date(this.formData(d))
    },
    methods: {
        reset(){//重置
            this.tooltip=false,
            this.ids=[]
            this.id=''
            this.currentDate=''
            this.currentDate2=''
            this.value=[0,0]
            let levelList = this.levelList
            let levelLists = levelList.map(item => {
                 item.checked = false
                 return item
            })
            console.log(levelLists)
            this.levelList=levelLists
        },
        onFocus(){//获取焦点
            if(this.ids.length>0 || this.currentDate || this.currentDate2 || this.value[0]>0 || this.value[1]>0){
                this.tooltip=true
            }else{
                this.tooltip=false
            }
        },
        formData(d) {
            let date = new Date(d)
            let y = date.getFullYear()
            let m = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
            let dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
            return `${y}-${m}-${dd}`
        },
        confirm() {//确认
            let d = this.formData(this.currentDate)
            this.currentDate = d
            this.show = false
        },
        cancel() {//取消
            this.show = false
        },
        confirm2() {//确认
            let d = this.formData(this.currentDate2)
            this.currentDate2 = d
            this.show2 = false
        },
        cancel2() {//取消
            this.show2 = false
        },
        async list() {
            const params = {
                startTime: this.currentDate,
                endTime: this.currentDate2,
                // lowestStudyTimeMinute: this.value[0] * 60,
                // highestStudyTimeMinute: this.value[1] * 60,
                levelMulStr: this.levelId,
                agentUserId: this.$store.state.userId
            }
            const { data } = await agentTrainees(params)
            if (data.code == 200) {
                if (this.value[0]) {
                    if(!data.data){
                        this.$message({
                        message: '没有找到符合条件的数据',
                        type: 'error'
                    });
                    return
                    }
                    let list = data.data.filter(item => {
                        return this.value[0] * 60 <= item.studyMinutes && item.studyMinutes <= this.value[1] * 60
                    })
                    this.tableData = list
                } else {
                    let list = data.data
                    this.tableData = list
                }
                console.log(this.tableData)
                if (this.tableData) {
                    this.$router.push({ path: '/searchsDes', query: { tableData: JSON.stringify(this.tableData) } })
                } else {
                    this.$message({
                        message: '没有找到符合条件的数据',
                        type: 'error'
                    });
                }
            }
        },
         async list2() {
            const params = {
                agentUserId: this.$store.state.userId
            }
            const { data } = await agentTrainees(params)
            if (data.code == 200) {
                
                    let list = data.data
                    let keyword=this.keyword
                    let res=list.filter(item=>{
                        return item.userName.indexOf(keyword)!=-1 || item.phoneNum.indexOf(keyword)!=-1 
                        //  || item.storeName.indexOf(keyword)!=-1 
                    })
                    console.log(res)
                if (res) {
                    this.$router.push({ path: '/searchsDes', query: { tableData: JSON.stringify(res) } })
                } else {
                    this.$message({
                        message: '没有找到符合条件的数据',
                        type: 'error'
                    });
                }
            }
        },
        selectLevel(index, item) {//选择等级
            let levelList = this.levelList
            levelList[index].checked = !item.checked
            let levelIds = levelList.filter(item => {
                return item.checked == true
            })
            let ids = levelIds.map(item => {
                return item.id
            })
            this.ids=ids
            let id = ids.join(',')
            this.levelId = id
            this.levelList = levelList
            if(this.ids.length>0 || this.currentDate || this.currentDate2 || this.value[0]>0 || this.value[1]>0){
                // this.tooltip=true
            }else{
                this.tooltip=false
            }
        },
        async leave() {//获取等级列表
            const { data } = await levelList({ roleId: 3 })
            if (data.code == 200) {
                data.data.map(item => {
                    item.checked = false
                })
                this.levelList = data.data
            } else {
                this.$message.error(data.msg)
            }
        },

    }
}
</script>

<style lang='less' scoped>
/deep/.el-input__inner {
    width: 90%;
    border-radius: 50px;
}

.noSelect {
    background: #f6f6f6;
    border-radius: 8px;
    font-size: 13px;
    color: #595959;
    width: 21.5%;
    height: 30px;
    line-height: 30px;
    margin: 5px;
    text-align: center;
}

.select {
    background: #EBF2FD;
    border-radius: 8px;
    font-size: 13px;
    color: #1890FF;
    width: 21.5%;
    height: 30px;
    line-height: 30px;
    margin: 5px;
    text-align: center;
    border: 1px solid #1890FF
}

.Notime {
    color: #8C8C8C;
    font-size: 15px;
}

.time {
    color: #1890FF;
    font-size: 15px;
}
</style>
